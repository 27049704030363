.underlined {
    flex: 1;
    text-decoration: none;
    background-image: linear-gradient(to right, yellow 0, yellow 100%);
    background-position: 0 1.2em;
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: background .5s;
    &:hover, {
        background-size: 100% 100%;
    }
    &--thin {
        background-image: linear-gradient(to right, black 0, black 100%);
    }
    &--thick {
        background-position: 0 -0.1em;
    }
    &--offset {
        background-position: 0 0.2em;
        //didn't find another solution than mask the underline shape by a box shadow with the same color than the bg
        box-shadow: inset 0 -.5em 0 0 white;
    }
    &--gradient {
        background-position: 0 -0.1em;
        background-image: linear-gradient(to right, yellow 0, lightgreen 100%);
    }
    &--reverse {
        background-position: 100% -0.1em;
        transition: background 1s; //yep, that's a long link
        background-image: linear-gradient(to right, yellow 0, yellow 100%);
    }
}

/**
 * Site header
 */
.site-header {
    border-bottom: 1px solid $grey-color-light;
    margin-top: -2rem;
    max-width: $max-width;
    p {
      font-size: $small-font-size;
    }
    .site-intro {
        font-size: $big-font-size;
    }
}

.smallcap {
    //text-transform: lowercase;
    //font-variant: small-caps;
    font-size: 1.5rem;
    font-weight: bold;

    a,
    a:hover {
        text-decoration: none;
        letter-spacing: 2px;
        background-color: #333;
        color: #eee;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }
}
/**
 * Page content
 */
.page-content {
    position: relative;
    padding: $spacing-unit ($spacing-unit * 0.75);
    margin: 1rem;
    box-sizing: border-box;
    max-width: $max-width;
}
/**
 * Pages
 */
.home {
    section + section {
        margin-top: $spacing-unit;
        max-width: $max-width;
    }
}

.post-list > li {
    margin-bottom: $spacing-unit / 4;
    list-style-type: none;
    margin-left: -2rem;

    a {
        color: $text-color;
        text-decoration: none;
        font-weight: normal;

        &:hover {
            color: $link-color;
            text-decoration: underline;
        }
    }

    time {
        font-size: $small-font-size;
        color: $grey-color;
        display: inline-block;
    }
    @include media-query($on-palm) {
        time {
            display: block;
            font-size: $small-font-size;
        }
    }
}

.tag-title {
    color: $link-color;
}
/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 2rem;
    letter-spacing: -1px;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.post-meta {
    font-size: $small-font-size;
    font-family: $code-font-family;
    color: $grey-color;
    a, a:visited {
      color: $grey-color-dark;
    }
    .tags {
        a, a:visited {
            background: $grey-color-light;
            padding: 0.1rem 0.5rem;
        }
    }
}

.post-content {
    margin-bottom: $spacing-unit;
    font-weight: normal;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 2rem;
        font-weight: normal;
    }

    h1,
    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1.25rem;
    }

    h5,
    h6 {
        font-size: 1rem;
    }
}

.copyright {
  margin-top: $spacing-unit;
  font-size: $small-font-size;
  font-family: $code-font-family;
  p {
    color: $grey-color;
    a, a:visited {
      color: $grey-color-dark;
    }
  }
}
